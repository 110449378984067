import type { FC } from 'react'
import type { RouteComponent } from '@ephemeris/types/src/reach-router'
import type {
  CustomizationStep,
  CustomizationSteps,
} from './CustomizationWizard'

import React from 'react'
import axios from 'axios'
import { DateTime } from 'luxon'
import { Buffer } from 'buffer'
import { motion } from 'framer-motion'
import { Listbox, Transition } from '@headlessui/react'
import {
  CheckIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/solid'
import memoizee from 'memoizee'
import startCase from 'lodash/startCase'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import padStart from 'lodash/padStart'
import debounce from 'lodash/debounce'
import orderBy from 'lodash/orderBy'

import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import { useModal } from '@ephemeris/react-components/src/Modal'
import {
  getDocument,
  getDocumentWidth,
} from '@ephemeris/utils/src/vanilla-javascript'
import { createBase64DataUrl } from '@ephemeris/utils/src/buffer'
import { safeParseJson } from '@ephemeris/utils/src/json'
import { percent } from '@ephemeris/utils/src/math'
import { formatShippingAddress } from '@ephemeris/utils/src/product-customization'
import { MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE } from '@ephemeris/constants/src/fulfillment/jewelry-production'
import Loader from '@ephemeris/react-components/src/Loader'
import EphemerisLogo from '@ephemeris/assets/src/images/svg/miscellanious/ephemeris-logo.svg'
import EngravingPreviewBaseGold from '@ephemeris/assets/src/images/png/jewelry/preview/background/back/gold.jpg'
import EngravingPreviewBaseSilver from '@ephemeris/assets/src/images/png/jewelry/preview/background/back/silver.jpg'

import {
  CustomizationWizard,
  useCustomizationWizardContext,
} from './CustomizationWizard'
import PreviewImage from '../PreviewImage'
import EngravingStep from './EngravingStep'
import { useProductCustomizationContext } from '../ProductCustomizationProvider'

interface LunarTalismanWizardProps {}

function getPreviewImageUrl({
  year,
  month,
  day,
  hemisphere,
  color,
}: JewelryProduction.Moon.Item['customizationInfo']) {
  let url = new URL(
    `${process.env.GATSBY_RENDERING_API_URL}/lunar/talisman/preview.png`
  )

  url.searchParams.append('year', `${year}`)
  url.searchParams.append('month', `${month}`)
  url.searchParams.append('day', `${day}`)
  url.searchParams.append('hemisphere', hemisphere)
  url.searchParams.append('color', color)

  const document = getDocument()

  if (document != null) {
    const size = getDocumentWidth() * window.devicePixelRatio

    url.searchParams.append('size', `${size > 1024 ? 1024 : size}`)
  }

  return url.toString()
}

const fetchPreview = memoizee(async (imageUrl: string) => {
  const { data, headers } = await axios.get(imageUrl, {
    responseType: 'arraybuffer',
  })
  const imageBuffer = Buffer.from(data, 'binary')
  const imageDataUrl = createBase64DataUrl(imageBuffer, 'image/png')
  const moonPhaseJson = headers['x-ephemeris-moon-phase']
  const moonPhase = safeParseJson<MoonPhase>(moonPhaseJson)

  return { imageDataUrl, moonPhase }
})

function hydrateCustomizationInfo(
  lunarTalisman: JewelryProduction.Moon.Item
): JewelryProduction.Moon.Item {
  const { customizationInfo } = lunarTalisman
  const { hasEngraving = false, hemisphere = 'north' } = customizationInfo
  const { year, month, day } = (() => {
    const { year, month, day } = customizationInfo

    if (!isNil(year) && !isNil(month) && !isNil(day)) {
      return { year, month, day }
    }

    const now = DateTime.local()
    return { year: now.year, month: now.month, day: now.day }
  })()

  return {
    ...lunarTalisman,
    customizationInfo: {
      ...customizationInfo,
      hasEngraving,
      year,
      month,
      day,
      hemisphere,
    },
  }
}

function renderEngravingPreview(
  canvas: HTMLCanvasElement,
  customizationInfo: JewelryProduction.AnyItem['customizationInfo']
) {
  const { width, height } = canvas
  const {
    engraving: { firstLine = '', secondLine = '' },
  } = customizationInfo

  const MIN_FONT_SIZE = 1
  const MAX_FONT_SIZE = width / (4 * devicePixelRatio)

  const ctx = canvas.getContext('2d')
  const pendantFrame = {
    x: percent(36.5, { of: width }),
    y: percent(55.6, { of: width }),
    width: percent(26.4, { of: width }),
    height: percent(26.4, { of: width }),
  }

  const color =
    (customizationInfo as JewelryProduction.Moon.CustomizationInfo).color ??
    (customizationInfo as JewelryProduction.BirthChart.CustomizationInfo).theme
      .background

  const getTextSize = (
    ctx: CanvasRenderingContext2D,
    text: string
  ): Geometry.Size => {
    const { width, actualBoundingBoxAscent, actualBoundingBoxDescent } =
      ctx.measureText(text)
    const height = actualBoundingBoxAscent + actualBoundingBoxDescent

    return {
      width,
      height,
    }
  }

  ctx.font = `bold ${MIN_FONT_SIZE}px Tangerine, AdobeNotDef`
  ctx.textBaseline = 'top'

  ctx.clearRect(0, 0, width, height)

  let fontSize = MIN_FONT_SIZE
  let { widestLineMeasures, widestText } = (() => {
    const firstLineMeasures = getTextSize(ctx, firstLine)
    const secondLineMeasures = getTextSize(ctx, secondLine)

    const [widestLineMeasures] = orderBy(
      [firstLineMeasures, secondLineMeasures],
      'width',
      'desc'
    )

    const widestText =
      widestLineMeasures.width === firstLineMeasures.width
        ? firstLine
        : secondLine

    return { widestLineMeasures, widestText }
  })()

  while (widestLineMeasures.width < pendantFrame.width) {
    fontSize += 1

    if (fontSize >= MAX_FONT_SIZE) {
      break
    }

    ctx.font = `bold ${fontSize}px Tangerine, AdobeNotDef`

    widestLineMeasures = getTextSize(ctx, widestText)
  }

  const firstLineSize = getTextSize(ctx, firstLine)
  const secondLineSize = getTextSize(ctx, secondLine)
  const secondLineTopMargin = pendantFrame.height * 0.01
  const firstLineCoordinates = {
    x: pendantFrame.x + pendantFrame.width / 2 - firstLineSize.width / 2,
    y:
      pendantFrame.y +
      pendantFrame.height / 2 -
      (firstLineSize.height +
        (isEmpty(secondLine)
          ? 0
          : secondLineTopMargin + secondLineSize.height)) /
        2,
  }
  const secondLineCoordinates = {
    x: pendantFrame.x + pendantFrame.width / 2 - secondLineSize.width / 2,
    y: firstLineCoordinates.y + firstLineSize.height + secondLineTopMargin,
  }

  ctx.fillStyle = 'rgba(255, 255, 255, 0.75)'
  ctx.fillText(
    firstLine,
    firstLineCoordinates.x + 1,
    firstLineCoordinates.y + 1
  )

  if (!isEmpty(secondLine)) {
    ctx.fillText(
      secondLine,
      secondLineCoordinates.x + 1,
      secondLineCoordinates.y + 1
    )
  }

  ctx.fillStyle = color === 'gold' ? '#806c37' : '#666565'
  ctx.fillText(firstLine, firstLineCoordinates.x, firstLineCoordinates.y)

  if (!isEmpty(secondLine)) {
    ctx.fillText(secondLine, secondLineCoordinates.x, secondLineCoordinates.y)
  }
}

const MomentStep: FC<{
  className?: string
  customizationInfo?: JewelryProduction.Moon.Item['customizationInfo']
  onChangeInfo: (
    date: Pick<DateComponents, 'year' | 'month' | 'day'>,
    hemisphere: Hemisphere
  ) => void
}> = ({
  onChangeInfo: handleChangeInfo,
  customizationInfo,
  className = '',
}) => {
  const isoDate = (() => {
    const { year, month, day } = customizationInfo ?? {}
    if (!year || !month || !day) {
      return DateTime.local().toISODate()
    }

    const padZero = (value: number) => padStart(`${value}`, 2, '0')

    return `${padZero(year)}-${padZero(month)}-${padZero(day)}`
  })()
  const [date, setDate] = React.useState<string>(isoDate)
  const [hemisphere, setHemisphere] = React.useState<Hemisphere>(
    customizationInfo?.hemisphere ?? 'north'
  )

  const onChangeDate = debounce(
    ({ target: { value: isoDate } }: React.ChangeEvent<HTMLInputElement>) => {
      setDate(isoDate)
    },
    300
  )

  React.useEffect(() => {
    const [year, month, day] = map(date.split('-'), Number)
    handleChangeInfo({ year, month, day }, hemisphere)
  }, [date, hemisphere])

  return (
    <div className={className}>
      <style>
        {
          '\
        input[type="date"]::-webkit-inner-spin-button,\
        input[type="date"]::-webkit-calendar-picker-indicator {\
            display: none;\
            -webkit-appearance: none;\
        }\
        input[type="date"]{\
          display:block;\
          -webkit-appearance: textfield;\
          -moz-appearance: textfield;\
        }\
      '
        }
      </style>
      <p className='lg:text-2xl block text-xl font-light'>
        What special moment do you want to immortalize?
      </p>

      <div className='flex gap-6 mt-4'>
        <div className='flex flex-col flex-grow'>
          <label className='font-bold'>Date:</label>
          <input
            className='rounded-xl border-darkBlue focus:outline-none flex items-center justify-between w-full px-4 py-3 text-left bg-white bg-opacity-75 border-2'
            defaultValue={isoDate}
            type='date'
            onChange={onChangeDate}
          />
        </div>
        <div className='flex flex-col flex-grow'>
          <label className='font-bold'>Hemisphere:</label>
          <Listbox value={hemisphere} onChange={setHemisphere}>
            {({ open }) => (
              <div className='relative w-full'>
                <Listbox.Button className='rounded-xl border-darkBlue focus:outline-none flex items-center justify-between w-full px-4 py-3 text-left bg-white bg-opacity-75 border-2'>
                  {startCase(hemisphere)}
                  {open ? (
                    <ChevronUpIcon className='w-6' />
                  ) : (
                    <ChevronDownIcon className='w-6' />
                  )}
                </Listbox.Button>
                <Transition
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Listbox.Options className='bg-gray-50 rounded-xl focus:outline-none absolute flex flex-col w-full gap-2 p-2 mt-1 shadow-lg'>
                    {map(['north', 'south'], hemisphere => {
                      return (
                        <Listbox.Option key={hemisphere} value={hemisphere}>
                          {({ active, selected }) => (
                            <div
                              className={`
                              flex items-center px-4 overflow-hidden rounded
                              ${active ? 'bg-darkBlue text-gray-50' : ''}
                            `}
                            >
                              {selected ? (
                                <CheckIcon className='w-4 mr-1' />
                              ) : (
                                <div className='mr-5' />
                              )}
                              <span className='py-2'>
                                {startCase(hemisphere)}
                              </span>
                            </div>
                          )}
                        </Listbox.Option>
                      )
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        </div>
      </div>
    </div>
  )
}

const ReviewStep: FC<{
  customizationInfo: JewelryProduction.Moon.Item['customizationInfo']
  lunarTalisman: JewelryProduction.Moon.Item
}> = ({ customizationInfo, lunarTalisman }) => {
  const {
    year,
    month,
    day,
    color,
    hemisphere,
    engraving,
    hasEngraving,
    moonPhase,
  } = customizationInfo
  const date = DateTime.utc(year, month, day)
  const formattedDate = date.toFormat('MMMM dd, yyyy')
  const moonPhaseName = startCase(moonPhase?.friendlyName)

  const InfoTitle: FC = ({ children }) => (
    <span className='text-sm font-bold'>{children}</span>
  )
  const InfoGroup: FC = ({ children }) => (
    <div className='flex justify-between w-full'>{children}</div>
  )
  const InfoItem: FC = ({ children }) => (
    <div className='flex flex-col w-full'>{children}</div>
  )

  return (
    <div>
      <span className='lg:text-2xl text-xl font-light'>
        Please, review the information below:
      </span>

      <div className='mt-4' />

      <InfoGroup>
        <InfoItem>
          <InfoTitle>Date:</InfoTitle>
          <span>{formattedDate}</span>
        </InfoItem>
        <InfoItem>
          <InfoTitle>Hemisphere:</InfoTitle>
          <span>{startCase(hemisphere)}</span>
        </InfoItem>
      </InfoGroup>

      <div className='mt-3' />

      <InfoGroup>
        <InfoItem>
          <InfoTitle>Color:</InfoTitle>
          <span>{startCase(color)}</span>
        </InfoItem>
        <InfoItem>
          <InfoTitle>Moon Phase:</InfoTitle>
          <span>{moonPhaseName}</span>
        </InfoItem>
      </InfoGroup>

      {hasEngraving && (
        <>
          <div className='mt-3' />

          {!isEmpty(engraving?.firstLine ?? '') && (
            <InfoItem>
              <InfoTitle>
                Engraving Line 1 (Max.{' '}
                {MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE} Char.):
              </InfoTitle>
              <span>
                {engraving?.firstLine ?? (
                  <span className='italic font-light opacity-50'>blank</span>
                )}
              </span>
            </InfoItem>
          )}
          {!isEmpty(engraving?.secondLine ?? '') && (
            <InfoItem>
              <InfoTitle>
                Engraving Line 2 (Max.{' '}
                {MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE} Char.):
              </InfoTitle>
              <span>
                {engraving?.secondLine ?? (
                  <span className='italic font-light opacity-50'>blank</span>
                )}
              </span>
            </InfoItem>
          )}
        </>
      )}
    </div>
  )
}

const LunarTalismanWizard: FC<RouteComponent & LunarTalismanWizardProps> = ({
  navigate,
}) => {
  const { customizableProduct } = useCustomizationWizardContext()
  const {
    updateCustomizableProduct,
    setStatus: setProductCustomizationStatus,
  } = useProductCustomizationContext()
  const [currentPreviewFace, setCurrentPreviewFace] = React.useState<
    'front' | 'back'
  >('front')
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] =
    React.useState(false)
  const [previewImageDataUrl, setPreviewImageDataUrl] = React.useState<string>()
  const [customizationInfo, setCustomizationInfo] = React.useState<
    JewelryProduction.Moon.Item['customizationInfo'] | undefined
  >()
  const canvasContainerRef = React.useRef<HTMLDivElement>()
  const engravingCanvasRef = React.useRef<HTMLCanvasElement>()
  const { showModal, dismissModal } = useModal()
  const queryFulfillmentApi = useFulfillmentApi()

  const lunarTalisman = customizableProduct as JewelryProduction.Moon.Item
  const { hasEngraving = false, moonPhase } = customizationInfo ?? {}

  const onChangeMomentInfo = (
    date: Pick<DateComponents, 'year' | 'month' | 'day'>,
    hemisphere: Hemisphere
  ) => {
    const { year, month, day } = date
    setCustomizationInfo({
      ...customizationInfo,
      year,
      month,
      day,
      hemisphere,
    })
  }

  const onChangeEngravingInfo = (engraving: {
    firstLine: string
    secondLine: string
  }) => setCustomizationInfo({ ...customizationInfo, engraving })

  const steps: CustomizationSteps = [
    {
      name: 'Moment',
      component: (
        <MomentStep
          customizationInfo={customizationInfo}
          onChangeInfo={onChangeMomentInfo}
        />
      ),
    },
    hasEngraving
      ? {
          name: 'Engraving',
          component: (
            <EngravingStep
              customizationInfo={customizationInfo}
              onChangeInfo={onChangeEngravingInfo}
            />
          ),
        }
      : null,
    {
      name: 'Review',
      component: (
        <ReviewStep
          lunarTalisman={lunarTalisman}
          customizationInfo={customizationInfo}
        />
      ),
    },
  ]

  const handleChangeStep = (step: CustomizationStep) => {
    setCurrentPreviewFace(step.name === 'Engraving' ? 'back' : 'front')
  }

  const handleConfirm = async () => {
    setProductCustomizationStatus('customizing')

    try {
      const jewelryItem = { ...lunarTalisman, customizationInfo }
      const response = await queryFulfillmentApi(
        `/jewelry-production/item/${lunarTalisman.id}/customize`,
        { jewelryItem },
        'PUT'
      )
      const { jewelryItem: updatedJewelryItem } = (await response.json()) as {
        jewelryItem: JewelryProduction.Moon.Item
      }

      updateCustomizableProduct(updatedJewelryItem)

      navigate('../', { replace: true })
    } catch (error) {
      console.log(`--- error:`, error)
      showModal({
        title: 'An error occurred',
        message: `This not your fault. Please, try again in a few minutes.`,
        body: (
          <div className='md:text-base text-sm'>
            <span className='block'>
              If the problem persists, please, contact us at{' '}
              <a href='mailto:help@ephemeris.co' className='underline'>
                help@ephemeris.co
              </a>
            </span>
            <button
              className='text-darkBlue border-darkBlue p-2 mt-4 border-2 rounded-lg'
              onClick={dismissModal}
            >
              Close
            </button>
          </div>
        ),
      })
    } finally {
      setProductCustomizationStatus('idle')
    }
  }

  React.useEffect(() => {
    const { customizationInfo } = hydrateCustomizationInfo(lunarTalisman)
    setCustomizationInfo(customizationInfo)
  }, [])

  React.useEffect(() => {
    if (isNil(customizationInfo)) {
      return
    }

    setIsLoadingPreviewImage(true)

    const imageUrl = getPreviewImageUrl(customizationInfo)

    ;(async () => {
      const { imageDataUrl, moonPhase } = await fetchPreview(imageUrl)

      setCustomizationInfo({ ...customizationInfo, moonPhase })
      setPreviewImageDataUrl(imageDataUrl)
      setIsLoadingPreviewImage(false)
    })()
  }, [
    customizationInfo?.year,
    customizationInfo?.month,
    customizationInfo?.day,
    customizationInfo?.hemisphere,
  ])

  React.useEffect(() => {
    const { current: canvas } = engravingCanvasRef
    const { engraving } = customizationInfo ?? {}

    if (isNil(canvas)) {
      return
    }

    if (isNil(engraving)) {
      return
    }

    renderEngravingPreview(canvas, customizationInfo)
  }, [engravingCanvasRef.current, customizationInfo?.engraving])

  return (
    <CustomizationWizard
      steps={steps}
      onChangeStep={handleChangeStep}
      onConfirm={handleConfirm}
    >
      <CustomizationWizard.Preview>
        <div className='relative'>
          <div className='min-w-screen min-h-w-screen lg:min-h-md lg:min-w-md relative'>
            <motion.div
              className='absolute inset-0'
              key='preview-back'
              style={{ backfaceVisibility: 'hidden' }}
              initial={{ rotateY: -180 }}
              animate={{
                rotateY: currentPreviewFace === 'back' ? 0 : -180,
              }}
              transition={{ type: 'keyframes' }}
            >
              <div
                className='relative w-full h-full overflow-hidden'
                ref={canvasContainerRef}
              >
                <div className='absolute inset-0'>
                  <PreviewImage
                    previewImageUrl={
                      customizationInfo?.color === 'gold'
                        ? EngravingPreviewBaseGold
                        : EngravingPreviewBaseSilver
                    }
                  />
                </div>
                <canvas
                  className='absolute inset-0 w-full h-full'
                  ref={engravingCanvasRef}
                  width={
                    canvasContainerRef.current?.clientWidth ??
                    0 * devicePixelRatio
                  }
                  height={
                    canvasContainerRef.current?.clientHeight ??
                    0 * devicePixelRatio
                  }
                />
              </div>
            </motion.div>
            <motion.div
              className='absolute inset-0'
              key='preview-front'
              style={{ backfaceVisibility: 'hidden' }}
              initial={{ rotateY: 0 }}
              animate={{
                rotateY: currentPreviewFace === 'front' ? 0 : 180,
              }}
              transition={{ type: 'keyframes' }}
            >
              <PreviewImage previewImageUrl={previewImageDataUrl} />
            </motion.div>
          </div>
          <div className='absolute left-0 flex justify-center w-full top-[8%]'>
            <div className='flex flex-col items-center justify-center w-full'>
              <img src={EphemerisLogo} className='w-1/3' alt='Ephemeris Logo' />
              <span className='font-copperplate text-xs'>
                Build your {lunarTalisman.jewelryType}
              </span>
            </div>
          </div>
          <div
            className={`
                absolute top-0 left-0 flex items-center justify-center w-full h-full bg-opacity-50 bg-darkBlue transition-opacity
                ${isLoadingPreviewImage ? 'opacity-100' : 'opacity-0'}
              `}
          >
            <Loader style='light' />
          </div>
        </div>

        <div className='mt-6' />

        <span className='block w-full mt-2 text-sm font-bold text-center'>
          Moon Phase:
        </span>
        <div className='relative'>
          <div
            className={`
              absolute flex flex-col items-center justify-center w-full h-full transition-opacity
              ${isLoadingPreviewImage ? 'opacity-100' : 'opacity-0'}
            `}
          >
            <div
              className={`w-1/3 h-4 rounded bg-darkBlue bg-opacity-30 animate-pulse transition-opacity`}
            />
          </div>
          <p
            className={`
              block text-xl lg:text-2xl font-light text-center transition-opacity
              ${
                isLoadingPreviewImage && !isNil(moonPhase)
                  ? 'opacity-0'
                  : 'opacity-100'
              }
            `}
          >
            {startCase(moonPhase?.friendlyName)}
          </p>
        </div>
      </CustomizationWizard.Preview>
    </CustomizationWizard>
  )
}

export default LunarTalismanWizard

import type React from 'react'
import type {
  ActionType,
  ProductCustomizationStatus,
} from './ProductCustomizationProvider'

export interface Actions {
  setCustomizableProducts(
    customizableProducts: ProductCustomization.Item[]
  ): void
  updateCustomizableProduct(
    customizableProduct: ProductCustomization.Item
  ): void
  setStatus(status: ProductCustomizationStatus): void
}

export default (
  dispatch: React.Dispatch<ReducerAction<ActionType>>
): Actions => {
  return {
    setCustomizableProducts: (
      customizableProducts: ProductCustomization.Item[]
    ) =>
      dispatch({
        type: 'SET_CUSTOMIZABLE_PRODUCTS',
        payload: { customizableProducts },
      }),
    updateCustomizableProduct: (
      customizableProduct: ProductCustomization.Item
    ) =>
      dispatch({
        type: 'UPDATE_CUSTOMIZABLE_PRODUCT',
        payload: { customizableProduct },
      }),
    setStatus: (status: ProductCustomizationStatus) =>
      dispatch({ type: 'SET_STATUS', payload: { status } }),
  }
}

import type { FC } from 'react'

import React from 'react'
import emojiRegex from 'emoji-regex/RGI_Emoji'

import { MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE } from '@ephemeris/constants/src/fulfillment/jewelry-production'

const EngravingInput: FC<JSX.IntrinsicElements['input']> = props => (
  <input
    {...props}
    className={`${
      props.className ?? ''
    } p-4 border-2 border-darkBlue rounded-xl bg-gray-50 bg-opacity-75 w-full focus:outline-none`}
    type='text'
    maxLength={MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE}
  />
)

const CharacterCounter: FC<
  { remainigCharactersCount: number } & React.HTMLAttributes<HTMLSpanElement>
> = props => {
  const { remainigCharactersCount } = props
  const status: 'high' | 'medium' | 'low' = (() => {
    switch (true) {
      case remainigCharactersCount <=
        MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE / 4:
        return 'low'
      case remainigCharactersCount <=
        MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE / 2:
        return 'medium'
      default:
        return 'high'
    }
  })()

  return (
    <div
      className={`${props.className} flex items-center justify-center w-7 h-7 bg-opacity-75 rounded-full bg-darkBlue transition-opacity`}
    >
      <span
        className={`
          text-sm
          ${
            status === 'high'
              ? 'text-emerald-300'
              : status === 'medium'
              ? 'text-amber-300'
              : status === 'low'
              ? 'text-rose-400'
              : ''
          }
        `}
      >
        {remainigCharactersCount}
      </span>
    </div>
  )
}

export const EngravingStep: FC<
  React.HTMLAttributes<HTMLDivElement> & {
    customizationInfo: JewelryProduction.AnyItem['customizationInfo']
    onChangeInfo: ({
      firstLine,
      secondLine,
    }: {
      firstLine?: string
      secondLine?: string
    }) => void
  }
> = ({ onChangeInfo, className = '', customizationInfo }) => {
  const [engravingInfo, setEngravingInfo] = React.useState<{
    firstLine?: string
    secondLine?: string
  }>(customizationInfo?.engraving)
  const [focusedLine, setFocusedLine] = React.useState<
    'firstLine' | 'secondLine'
  >()
  const firstLine = engravingInfo?.firstLine ?? ''
  const secondLine = engravingInfo?.secondLine ?? ''

  const onChangeText = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value: text } = target

    if (text.length > MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE) {
      return
    }

    const filteredText = text.replace(emojiRegex(), '')
    const engravingLine = target.getAttribute('data-engraving-line')

    setEngravingInfo({ ...engravingInfo, [engravingLine]: filteredText })
  }

  const getRemainingCharactersCount = (characterCount: number) =>
    MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE - characterCount

  React.useEffect(() => {
    onChangeInfo(engravingInfo)
  }, [engravingInfo])

  return (
    <div className={className}>
      <p className='text-xl font-light lg:text-2xl'>
        What do you want to have engraved on the back of you Talisman?
      </p>

      <div className={`flex flex-col gap-2 w-full mt-4`}>
        <div className='w-full'>
          <div className='flex items-center justify-between'>
            <label className='font-bold'>
              Engraving Line 1 (Max.{' '}
              {MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE} Char.)
            </label>
            <CharacterCounter
              className={focusedLine === 'firstLine' ? '' : 'opacity-0'}
              remainigCharactersCount={getRemainingCharactersCount(
                firstLine.length
              )}
            />
          </div>
          <EngravingInput
            className='mt-1'
            onChange={onChangeText}
            data-engraving-line='firstLine'
            value={firstLine}
            onFocus={() => setFocusedLine('firstLine')}
            onBlur={() => setFocusedLine(undefined)}
            autoFocus={firstLine.length === 0 && secondLine.length === 0}
          />
        </div>
        <div className='w-full'>
          <div className='flex items-center justify-between'>
            <label className='font-bold'>
              Engraving Line 2 (Max.{' '}
              {MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE} Char.)
            </label>
            <CharacterCounter
              className={focusedLine === 'secondLine' ? '' : 'opacity-0'}
              remainigCharactersCount={getRemainingCharactersCount(
                secondLine.length
              )}
            />
          </div>
          <EngravingInput
            className='mt-1'
            onChange={onChangeText}
            data-engraving-line='secondLine'
            value={secondLine}
            onFocus={() => setFocusedLine('secondLine')}
            onBlur={() => setFocusedLine(undefined)}
          />
        </div>
      </div>
    </div>
  )
}

import type { FC } from 'react'
import type { CustomizationStep } from './CustomizationWizard'
import type { ProductCustomizationStatus } from '../ProductCustomization'

import React from 'react'
import { useWizard } from 'react-use-wizard'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import isNil from 'lodash/isNil'

import Loader from '@ephemeris/react-components/src/Loader'
import { useProductCustomizationContext } from '../ProductCustomizationProvider'

const StepControllerButton = React.forwardRef<
  HTMLButtonElement,
  JSX.IntrinsicElements['button']
>((props, ref) => {
  const { children, className = '' } = props
  return (
    <button
      {...props}
      ref={ref}
      className={`${className} flex flex-col justify-center items-center w-full font-bold rounded-xl p-4 h-16 focus:outline-none transition-transform`}
    >
      {children}
    </button>
  )
})

const StepController: FC<{
  steps: CustomizationStep[]
  onConfirm: () => void
  onChangeStep?: (step: CustomizationStep, stepIndex: number) => void
}> = ({ steps, onChangeStep, onConfirm }) => {
  const {
    previousStep: returnToPreviousStep,
    nextStep: proceedToNextStep,
    activeStep,
    isFirstStep,
    isLastStep,
  } = useWizard()
  const previousStepButtonRef = React.useRef<HTMLButtonElement>()
  const { status: productCustomizationStatus } =
    useProductCustomizationContext()

  const getPreviousStepIndex = (stepIndex: number) => {
    const previous = stepIndex - 1
    return previous < 0 ? 0 : previous
  }
  const getNextStepIndex = (stepIndex: number) => {
    const next = stepIndex + 1
    return next === steps.length ? steps.length - 1 : next
  }
  const getStep = (stepIndex: number): CustomizationStep | undefined =>
    steps[stepIndex]

  const nextStepIndex = getNextStepIndex(activeStep)
  const previousStepIndex = getPreviousStepIndex(activeStep)
  const nextStep = getStep(nextStepIndex)
  const previousStep = getStep(previousStepIndex)
  const nextStepButtonTitle = nextStep?.name
  const previousStepButtonTitle = previousStep?.name

  React.useEffect(() => {
    if (isNil(onChangeStep)) {
      return
    }

    const currentStep = getStep(activeStep)
    onChangeStep(currentStep, activeStep)
  }, [activeStep])

  return (
    <>
      <div className='flex items-center justify-between gap-4 mt-4'>
        {!isFirstStep && (
          <StepControllerButton
            ref={previousStepButtonRef}
            onClick={() => {
              returnToPreviousStep()
            }}
            className='relative px-8 border-2 border-darkBlue text-darkBlue'
            disabled={productCustomizationStatus === 'loading'}
          >
            <div
              className='absolute top-0 flex flex-col items-center justify-center h-full left-2'
              style={{ height: previousStepButtonRef.current?.clientHeight }}
            >
              <ChevronLeftIcon className='w-6' />
            </div>
            <span className='flex flex-col'>
              Edit
              <span className='text-xs italic font-light'>
                ({previousStepButtonTitle})
              </span>
            </span>
          </StepControllerButton>
        )}
        {!isLastStep ? (
          <StepControllerButton
            onClick={() => {
              proceedToNextStep()
            }}
            className='bg-darkBlue text-gray-50'
            disabled={productCustomizationStatus === 'loading'}
          >
            <div className='flex flex-col items-center'>
              <span>Next</span>
              <span className='text-xs italic font-light'>
                ({nextStepButtonTitle})
              </span>
            </div>
          </StepControllerButton>
        ) : (
          <StepControllerButton
            onClick={onConfirm}
            className='bg-darkBlue text-gray-50'
            disabled={
              productCustomizationStatus === 'loading' ||
              productCustomizationStatus === 'customizing'
            }
          >
            {productCustomizationStatus === 'loading' ||
            productCustomizationStatus === 'customizing' ? (
              <div className='flex justify-center w-full'>
                <Loader style='light' />
              </div>
            ) : (
              <>Confirm</>
            )}
          </StepControllerButton>
        )}
      </div>
      <AnimatePresence>
        {isLastStep && (
          <motion.span
            className='block p-1 text-xs md:text-sm'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            *After you confirm this information your order will go into
            production and it will no longer be possible to change it.
          </motion.span>
        )}
      </AnimatePresence>
    </>
  )
}

export default StepController

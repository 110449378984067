import { π } from './math'

const ONE_INCH_IN_CENTIMETERS = 2.54

export function convertInchesToCentimeters(inches: number) {
  return inches * ONE_INCH_IN_CENTIMETERS
}

export function degToRad(degrees: number) {
  return (degrees * π) / 180
}

export function radToDeg(radians: number) {
  return (radians * 180) / π
}

export function convertEclipticAngleToTrigonometricAngle(
  eclipticAngle: number
) {
  const angle = 180 + eclipticAngle

  return angle >= 360 ? angle - 360 : angle
}

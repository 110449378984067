import type { State, ActionType } from './ProductCustomizationProvider'

import _ from 'lodash'

interface Action {
  type: ActionType
  payload: any
}

export default function reducer(state: State, action: Action): State {
  const { type, payload } = action

  switch (type) {
    case 'SET_CUSTOMIZABLE_PRODUCTS':
      return { ...state, customizableProducts: payload.customizableProducts }

    case 'UPDATE_CUSTOMIZABLE_PRODUCT': {
      const { customizableProducts = [] } = state
      const { customizableProduct: updatedCustomizableProduct } = payload as {
        customizableProduct: ProductCustomization.Item
      }

      const updatedCustomizableProducts = _.map(
        customizableProducts,
        customizableProduct =>
          updatedCustomizableProduct.id === customizableProduct.id
            ? updatedCustomizableProduct
            : customizableProduct
      )

      return { ...state, customizableProducts: updatedCustomizableProducts }
    }

    case 'SET_STATUS':
      return { ...state, status: payload.status }

    default:
      return state
  }
}

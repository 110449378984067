import { useEffect } from 'react'

export default function useWindowResize(
  handleResize: () => void,
  dependencies?: React.DependencyList
) {
  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, dependencies)
}

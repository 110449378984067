export function getLunarTalismanPreviewImageUrl(
  customizationInfo: JewelryProduction.Moon.CustomizationInfo,
  options?: FullyPartial<{ size: number }>
) {
  const { year, month, day, hemisphere, color } = customizationInfo
  let url = new URL(
    `${process.env.GATSBY_RENDERING_API_URL}/lunar/talisman/preview.png`
  )

  url.searchParams.append('year', `${year}`)
  url.searchParams.append('month', `${month}`)
  url.searchParams.append('day', `${day}`)
  url.searchParams.append('hemisphere', hemisphere)
  url.searchParams.append('color', color)
  url.searchParams.append('size', `${options?.size ?? ''}`)

  return url.toString()
}

import type { FC } from 'react'

import React from 'react'
import ReactDOMServer from 'react-dom/server'
import safeStringify from 'fast-safe-stringify'
import _ from 'lodash'

import lambda, { getFunctionName } from './aws/lambda'
import { safeParseJson } from './json'

export function renderReactElementToString(
  element: React.ReactElement
): string {
  return ReactDOMServer.renderToString(element)
}

export async function convertElementToPngBase64DataUrl(
  element: React.ReactElement
): Promise<string> {
  const html = renderReactElementToString(element)
  const dataUrlType: ImageDataUrlType = 'png'

  const { Payload } = await lambda
    .invoke({
      FunctionName: getFunctionName({
        service: 'render-html-as-base64-data-url',
        name: 'renderHtmlAsBase64DataUrl',
      }),
      Payload: JSON.stringify({
        html,
        dataUrlType,
      }),
    })
    .promise()

  const payload = safeParseJson(Payload as string)
  const { dataUrl } = safeParseJson(payload.body)

  return dataUrl as string
}

export function createKey(obj: AnyObject): string {
  return safeStringify(obj)
}

export function getChild(children: React.ReactNode | undefined, component: FC) {
  if (_.isNil(children)) {
    return
  }

  const childrenArray = React.Children.toArray(children)

  return _.find(childrenArray, (child: React.ReactElement) => {
    if (!child.type) {
      return false
    }

    const type = child.type as React.FunctionComponent
    const name = type.displayName

    return name === component.displayName
  })
}

import {
  fill,
  isNil,
  padStart,
  take,
  takeRight,
  orderBy,
  findIndex,
  find,
} from 'lodash'

import {
  MAX_BATCH_SIZE,
  ORDERED_BATCH_PRODUCTION_STATUSES,
} from '@ephemeris/constants/dist/fulfillment/jewelry-production'

export function isProductionBatch(
  batch: JewelryProduction.AnyBatch
): batch is JewelryProduction.Batch {
  return !isNil((batch as JewelryProduction.Batch).createdAt)
}

export function isPreviewBatch(
  batch: JewelryProduction.AnyBatch
): batch is JewelryProduction.PreviewBatch {
  return batch.id.startsWith('batch-preview')
}

export function isBirthChartJewelryItem(
  jewelryItem: JewelryProduction.AnyItem
): jewelryItem is JewelryProduction.BirthChart.Item {
  return (
    (jewelryItem as JewelryProduction.BirthChart.Item).type === 'BirthChart'
  )
}

export function isMoonJewelryItem(
  jewelryItem: JewelryProduction.AnyItem | ProductCustomization.Item
): jewelryItem is JewelryProduction.Moon.Item {
  return (jewelryItem as JewelryProduction.Moon.Item).type === 'Moon'
}

export function isSynastryJewelryItem(
  jewelryItem: JewelryProduction.AnyItem
): jewelryItem is JewelryProduction.Synastry.Item {
  return (jewelryItem as JewelryProduction.Synastry.Item).type === 'Synastry'
}

export function createPreviewBatch(
  jewelryItems: (JewelryProduction.AnyItem | null)[],
  batchNumber: number
) {
  const batchItems = take(jewelryItems, MAX_BATCH_SIZE)
  const remainigItemsCount = jewelryItems.length - batchItems.length
  const remainingItems = takeRight(jewelryItems, remainigItemsCount)

  const id = `batch-preview-${padStart(`${batchNumber}`, 2, '0')}`

  const numberOfEmptySlots = MAX_BATCH_SIZE - batchItems.length
  const emptySlots = fill<null>(Array(numberOfEmptySlots), null)
  const batch: JewelryProduction.PreviewBatch = {
    id,
    batchNumber,
    jewelryItems: [...batchItems, ...emptySlots],
  }

  return { batch, remainingItems }
}

export function createEmptyPreviewBatch(
  batchNumber: number
): JewelryProduction.PreviewBatch {
  const { batch } = createPreviewBatch([], batchNumber)

  return batch
}

export function sortBatchJewelryItems(
  batch: JewelryProduction.AnyBatch,
  sortingAttribute?: string
) {
  const updatedJewelrytems = orderBy(
    batch.jewelryItems,
    sortingAttribute ?? 'orderCreatedAt'
  )

  return { ...batch, jewelryItems: updatedJewelrytems }
}

export function getNextBatchStatus(
  batch: JewelryProduction.Batch
): JewelryProduction.BatchStatus {
  const { status: currentStatus } = batch
  const currentStatusIndex = findIndex(
    ORDERED_BATCH_PRODUCTION_STATUSES,
    status => status === currentStatus
  )
  try {
    const nextStatus = ORDERED_BATCH_PRODUCTION_STATUSES[currentStatusIndex + 1]
    return nextStatus
  } catch {
    throw {
      name: 'NoFurtherStatusError',
      message: `This batch has reached it's final status`,
    }
  }
}

export function isSuspendedJewelryProductionItem(
  jewelryItem: JewelryProduction.Item | JewelryProduction.SuspendedItem
): jewelryItem is JewelryProduction.SuspendedItem {
  return jewelryItem.status === 'Suspended'
}

export function getJewelryItemById(
  jewelryItems: JewelryProduction.Item[],
  jewelryItemId: string
): JewelryProduction.Item | undefined {
  return find(jewelryItems, ({ id }) => id === jewelryItemId)
}

export function getJigSlotCoordinate(slotIndex: number) {
  const ROW_NAMES = ['A', 'B', 'C']

  const rowIndex = Math.floor(slotIndex / 6)
  const columnIndex = slotIndex % 6

  const x = ROW_NAMES[rowIndex]
  const y = columnIndex + 1

  return `${x}${y}`
}

import type { FC } from 'react'
import type { RouteComponent } from '@ephemeris/types/src/reach-router'

import React from 'react'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'

import EphemerisLogo from '@ephemeris/assets/src/images/svg/miscellanious/ephemeris-logo.svg'

import ListItem from './ListItem'
import { useProductCustomizationContext } from '../ProductCustomizationProvider'
import { isMoonJewelryItem } from '@ephemeris/utils/src/jewelry-production'
import { isLunarPoster } from '@ephemeris/utils/src/product-customization'

export const ProductsList: FC<RouteComponent> = () => {
  const { customizableProducts } = useProductCustomizationContext()

  const { customizedProducts, nonCustomizedProducts } = reduce(
    customizableProducts,
    (acc, customizableProduct) => {
      const { customizedAt } = customizableProduct
      const { customizedProducts, nonCustomizedProducts } = acc

      /**
       * Right now we only support customization of Lunar Talisman and Lunar Poster
       */
      if (
        !isMoonJewelryItem(customizableProduct) &&
        !isLunarPoster(customizableProduct)
      ) {
        return acc
      }

      return {
        ...acc,
        customizedProducts: !!customizedAt
          ? [...customizedProducts, customizableProduct]
          : customizedProducts,
        nonCustomizedProducts: !!customizedAt
          ? nonCustomizedProducts
          : [...nonCustomizedProducts, customizableProduct],
      }
    },
    {
      customizedProducts: [],
      nonCustomizedProducts: [],
    } as {
      customizedProducts: ProductCustomization.Item[]
      nonCustomizedProducts: ProductCustomization.Item[]
    }
  )

  const nonCustomizedProductsList = isEmpty(nonCustomizedProducts)
    ? null
    : map(nonCustomizedProducts, (customizableProduct, index) => (
        <div
          className='border-t border-opacity-50 border-darkGold first:border-t-0'
          key={`non-customized-product-${index}`}
        >
          <ListItem.NonCustomized customizableProduct={customizableProduct} />
        </div>
      ))

  const customizedItemsList = isEmpty(customizedProducts)
    ? null
    : map(customizedProducts, (customizedProduct, index) => (
        <div
          className='border-t border-opacity-50 border-darkGold first:border-t-0'
          key={`customized-product-${index}`}
        >
          <ListItem.Customized customizableProduct={customizedProduct} />
        </div>
      ))

  return (
    <>
      <div className='flex justify-center w-full pt-8 lg:pt-11'>
        <img src={EphemerisLogo} className='w-1/2 lg:w-1/3' />
      </div>

      <div className='mt-4 lmd:mt-8' />

      <div className='flex justify-center w-full bg-lightGold'>
        <div className='w-full lg:max-w-3/4'>
          {isNil(nonCustomizedProductsList) ? (
            <div className='flex flex-col w-full gap-2 p-4 mt-4 text-center'>
              <span className='text-xl font-bold leading-none text-center sm:text-2xl md:text-3xl lg:text-4xl lg:px-0 font-exo2'>
                No more items to customize!
              </span>
              <span className='text-sm text-center md:text-base lg:text-2xl font-exo2'>
                Your items are now being made and will soon be shipped to you
              </span>
            </div>
          ) : (
            <>
              <h1 className='px-4 text-base font-light md:px-6 lg:px-8 sm:text-lg md:text-xl lg:text-2xl font-exo2'>
                Select the item you want to customize:
              </h1>

              <div className='mt-2 lg:mt-4' />

              <div className='w-full lg:overflow-hidden lg:rounded-xl lg:shadow-md'>
                {nonCustomizedProductsList}
              </div>
            </>
          )}

          <div className='mt-6 md:mt-8' />

          {isNil(customizedItemsList) ? null : (
            <>
              <h1 className='px-4 text-base font-light md:px-6 lg:px-8 sm:text-lg md:text-xl lg:text-2xl font-exo2'>
                Items already customized:
              </h1>

              <div className='mt-2 lg:mt-4' />

              <div className='w-full lg:overflow-hidden lg:rounded-xl lg:shadow-md'>
                {customizedItemsList}
              </div>
            </>
          )}
          <div className='pb-8 mt-4 ml-2'>
            <span className='text-sm font-light'>
              Need help? Send us a message at{' '}
              <a
                href='mailto:help@ephemeris.co'
                className='font-semibold text-indigo-800 underline'
              >
                help@ephemeris.co
              </a>
              !
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

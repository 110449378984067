import { PRODUCT_PRETTY_NAME_BY_PRODUCT_ID } from '@ephemeris/constants/dist/store'

import { convertInchesToCentimeters } from './units'

export function isLunarTalisman(
  customizableProduct: Product.CustomizableProduct
): customizableProduct is Product.LunarTalisman {
  return (
    customizableProduct.category === 'jewelry' &&
    customizableProduct.printType === 'lunar'
  )
}

export function isBirthChartTalisman(
  customizableProduct: Product.CustomizableProduct
): customizableProduct is Product.BirthChartTalisman {
  return (
    customizableProduct.category === 'jewelry' &&
    customizableProduct.printType === 'birth_chart'
  )
}

export function isLunarPoster(
  customizableProduct: ProductCustomization.Item
): customizableProduct is Product.LunarPoster {
  return (
    (customizableProduct as Product.LunarPoster).productionCategory ===
      'poster' &&
    (customizableProduct as Product.LunarPoster).printType === 'lunar'
  )
}

export function isBirthChartPoster(
  customizableProduct: Product.CustomizableProduct
): customizableProduct is Product.BirthChartPoster {
  return (
    customizableProduct.category === 'wall_art' &&
    customizableProduct.printType === 'birth_chart'
  )
}

export function isJewelryItem(
  customizableItem: ProductCustomization.Item
): customizableItem is JewelryProduction.Item {
  return !!(customizableItem as JewelryProduction.Item).jewelryType
}

export function isPoster(
  customizableItem: ProductCustomization.Item
): customizableItem is Product.Poster {
  return (customizableItem as Product.Poster).productionCategory === 'poster'
}

export function getProductPrettyName({ productId }: ProductCustomization.Item) {
  return PRODUCT_PRETTY_NAME_BY_PRODUCT_ID[productId]
}

export function formatShippingAddress(
  shippingAddress: Product.ShippingAddress
) {
  const {
    address1,
    address2,
    firstName,
    lastName,
    province,
    city,
    country,
    postalCode,
  } = shippingAddress

  return `${firstName} ${lastName}, ${address1}${
    address2 ? ` ${address2}` : ''
  }, ${city}${province ? `, ${province}` : ''}, ${country} – ${postalCode}`
}

export function getCompletePosterSize(size: Product.WallArt.Poster.Size) {
  const [width, height] = size.split('x').map(dimension => {
    const value = Number(dimension)
    return Math.floor(convertInchesToCentimeters(value))
  })

  return `${size} in. (${width}cm x ${height}cm)`
}

import '../../css/index.css'

import React from 'react'
import { Router } from '@reach/router'
import { motion, AnimatePresence } from 'framer-motion'
import isNil from 'lodash/isNil'

import { ModalProvider } from '@ephemeris/react-components/src/Modal'
import EphemerisLogo from '@ephemeris/assets/src/images/svg/miscellanious/ephemeris-logo.svg'

import CustomizationWizard from './CustomizationWizard'
import {
  ProductCustomizationProvider,
  useProductCustomizationContext,
} from './ProductCustomizationProvider'

import ProductsList from './ProductsList'

export type ProductCustomizationStatus = 'idle' | 'loading' | 'customizing'

interface ProductCustomizationProps {
  orderId: string
}

const ProductCustomization = () => {
  const { customizableProducts } = useProductCustomizationContext()

  return (
    <div className='w-full h-full bg-lightGold text-darkBlue font-exo2'>
      <AnimatePresence>
        {isNil(customizableProducts) && (
          <motion.div
            className='flex flex-col items-center justify-center w-full h-full'
            layout
            key='loading'
            initial={{
              opacity: 1,
              translateY: '0%',
            }}
            exit={{
              opacity: 0,
              translateY: '-30%',
            }}
          >
            <img src={EphemerisLogo} className='w-1/2 lg:w-1/3 animate-pulse' />
          </motion.div>
        )}
        {!isNil(customizableProducts) && (
          <motion.div
            className='w-full h-full'
            layout
            key='main-content'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Router className='h-full'>
              <ProductsList path='/:orderId' />
              <CustomizationWizard path='/:orderId/*' />
            </Router>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ({ orderId }: ProductCustomizationProps) => (
  <>
    {/* Load font */}
    <span className='font-tangerine' />
    <span className='font-not-def' />
    <ModalProvider rootElementId='modal-root'>
      <ProductCustomizationProvider orderId={orderId}>
        <ProductCustomization />
      </ProductCustomizationProvider>
    </ModalProvider>
  </>
)

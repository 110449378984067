import type { FC } from 'react'

import React from 'react'
import { Link } from '@reach/router'
import { DateTime } from 'luxon'
import { ChevronRightIcon } from '@heroicons/react/solid'
import isNil from 'lodash/isNil'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'

import {
  getProductPrettyName,
  isLunarTalisman,
  isLunarPoster,
  getCompletePosterSize,
} from '@ephemeris/utils/src/product-customization'
import { isMoonJewelryItem } from '@ephemeris/utils/src/jewelry-production'
import { getChild } from '@ephemeris/utils/src/react'
import { getLunarPosterPreviewImageUrl } from '@ephemeris/utils/src/product-customization/lunar-poster'
import { getLunarTalismanPreviewImageUrl } from '@ephemeris/utils/src/product-customization/lunar-talisman'
import LunarNecklaceIcon from '@ephemeris/assets/src/images/icons/lunar-necklace.inline.svg'
import LunarPosterIcon from '@ephemeris/assets/src/images/icons/lunar-poster.inline.svg'
import Loader from '@ephemeris/react-components/src/Loader'

interface ListItemByStatusProps {
  NonCustomized: FC<{ customizableProduct: ProductCustomization.Item }>
  Customized: FC<{ customizableProduct: ProductCustomization.Item }>
}

interface ListItemProps {
  customizableProduct: ProductCustomization.Item
  destinationPath?: string
}

interface ListItemComposites {
  Body: FC
  Thumbnail: FC
  DisclosureIndicator: FC
}

const PreviewImage = React.forwardRef<
  HTMLImageElement,
  { imageSource: string }
>(({ imageSource }, ref) => {
  const [isLoading, setIsLoading] = React.useState(true)

  const handleLoadFinished = () => {
    setIsLoading(false)
  }

  return (
    <div className='relative h-full w-96 max-w-[inherit]'>
      <img
        ref={ref}
        src={imageSource}
        className='w-full'
        onLoad={handleLoadFinished}
      />
      {isLoading && (
        <div className='absolute inset-0 flex items-center justify-center bg-darkBlue bg-opacity-30'>
          <Loader style={'light'} />
        </div>
      )}
    </div>
  )
})

const ListItemIcon: FC = ({ children }) => (
  <div className='flex flex-col justify-center h-full text-darkBlue'>
    <div className='flex justify-center p-2 max-w-12 max-h-12 xs:max-w-20 xs:max-h-20 lg:max-w-24 lg:max-h-24'>
      {children}
    </div>
  </div>
)

const ListItem: FC<ListItemProps> & ListItemComposites = ({
  customizableProduct,
  destinationPath,
  ...props
}) => {
  const title = getProductPrettyName(customizableProduct)
  const children = React.Children.toArray(props.children)
  const thumbnail = getChild(children, ListItem.Thumbnail)
  const body = getChild(children, ListItem.Body)
  const disclosureIndicator = getChild(children, ListItem.DisclosureIndicator)
  const customizationDate = DateTime.fromISO(customizableProduct.customizedAt)

  const listItem = (
    <div className='flex w-full p-2 py-4 xs:p-4 text-xs bg-opacity-75 xs:text-sm sm:text-base md:text-lg bg-[snow]'>
      {thumbnail}
      <div className='flex flex-col ml-4 md:ml-6'>
        <h3 className='text-xs font-bold xs:text-sm sm:text-lg md:text-2xl'>
          {title}
        </h3>
        {customizationDate.isValid && (
          <span className='italic text-2xs xs:text-xs'>
            Customized on:{' '}
            <span className='font-light'>
              {customizationDate.toFormat('MMMM dd, yyyy')}
            </span>
          </span>
        )}

        <div className='mt-1 xs:mt-2' />

        {body}
      </div>

      {disclosureIndicator && (
        <div
          className={`
            flex justify-end flex-grow lg:pr-4
          `}
        >
          {disclosureIndicator}
        </div>
      )}
    </div>
  )

  if (isNil(destinationPath)) {
    return listItem
  }

  return (
    <Link
      to={destinationPath}
      state={{ customizableProduct }}
      className='cursor-pointer '
    >
      {listItem}
    </Link>
  )
}

ListItem.Thumbnail = ({ children }) => (
  <div className='max-w-20 xs:max-w-32 sm:max-w-36 md:max-w-44 lg:max-w-48'>
    {children}
  </div>
)
ListItem.Thumbnail.displayName = 'Thumbnail'

ListItem.Body = ({ children }) => <>{children}</>
ListItem.Body.displayName = 'Body'

ListItem.DisclosureIndicator = ({ children }) => <>{children}</>
ListItem.DisclosureIndicator.displayName = 'DisclosureIndicator'

const LunarTalismanListItem: FC<{
  lunarTalisman: JewelryProduction.Moon.Item
  destinationPath?: string
  thumbnail: JSX.Element
}> = ({ lunarTalisman, destinationPath, thumbnail }) => {
  const {
    customizationInfo: {
      color,
      year,
      month,
      day,
      hemisphere,
      engraving = {},
      moonPhase,
    },
    customizedAt,
  } = lunarTalisman
  const { firstLine = '', secondLine = '' } = engraving
  const date = DateTime.utc(year ?? -1, month ?? -1, day ?? -1)
  const jewelryType = startCase(lunarTalisman.jewelryType)
  const isCustomized = !!customizedAt

  return (
    <ListItem
      destinationPath={destinationPath}
      customizableProduct={lunarTalisman}
    >
      <ListItem.Thumbnail>{thumbnail}</ListItem.Thumbnail>
      <ListItem.Body>
        <span>
          <span className='font-semibold'>Color: </span> {startCase(color)}
        </span>
        <span>
          <span className='font-semibold'>Type: </span> {jewelryType}
        </span>
        {date.isValid && (
          <span>
            <span className='font-bold'>Date:</span>{' '}
            {date.toFormat('MMMM dd, yyyy')}
          </span>
        )}
        {!isNil(hemisphere) && (
          <span>
            <span className='font-bold'>Hemisphere:</span>{' '}
            {startCase(hemisphere)}
          </span>
        )}
        {!isNil(moonPhase?.friendlyName) && (
          <span>
            <span className='font-bold'>Moon Phase:</span>{' '}
            {startCase(moonPhase?.friendlyName)}
          </span>
        )}
        {isCustomized && !isEmpty(firstLine) && (
          <span>
            <span className='font-bold'>Engraving First Line:</span> {firstLine}
          </span>
        )}
        {isCustomized && !isEmpty(secondLine) && (
          <span>
            <span className='font-bold'>Engraving Second Line:</span>{' '}
            {secondLine}
          </span>
        )}
      </ListItem.Body>
      {!customizedAt && (
        <ListItem.DisclosureIndicator>
          <ChevronRightIcon className={`w-6 h-full sm:w-7 md:w-8`} />
        </ListItem.DisclosureIndicator>
      )}
    </ListItem>
  )
}

const NonCustomizedLunarTalismanListItem: FC<{
  lunarTalisman: JewelryProduction.Moon.Item
}> = ({ lunarTalisman }) => {
  return (
    <LunarTalismanListItem
      lunarTalisman={lunarTalisman}
      destinationPath='lunar-talisman'
      thumbnail={
        <ListItemIcon>
          <LunarNecklaceIcon />
        </ListItemIcon>
      }
    />
  )
}

const CustomizedLunarTalismanListItem: FC<{
  lunarTalisman: JewelryProduction.Moon.Item
}> = ({ lunarTalisman }) => {
  const previewImageRef = React.useRef<HTMLImageElement>()
  const [previewImageUrl, setPreviewImageUrl] = React.useState<string>()

  React.useEffect(() => {
    if (!previewImageRef.current) {
      return
    }

    const size = previewImageRef.current.clientWidth * window.devicePixelRatio

    const previewImageUrl = getLunarTalismanPreviewImageUrl(
      lunarTalisman.customizationInfo,
      { size }
    )

    setPreviewImageUrl(previewImageUrl)
  }, [previewImageRef.current])

  return (
    <LunarTalismanListItem
      lunarTalisman={lunarTalisman}
      thumbnail={
        <PreviewImage ref={previewImageRef} imageSource={previewImageUrl} />
      }
    />
  )
}

const LunarPosterListItem: FC<{
  lunarPoster: Product.LunarPoster
  destinationPath?: string
  thumbnail: JSX.Element
}> = ({ lunarPoster, destinationPath, thumbnail }) => {
  const {
    customizationInfo: {
      theme,
      year = -1,
      month = -1,
      day = -1,
      hemisphere,
      place,
      message,
      frame,
      moonPhase,
    },
    customizedAt,
  } = lunarPoster
  const { background, foreground } = theme ?? {}
  const hasColorInfo = !isNil(background) && !isNil(foreground)
  const date = DateTime.utc(year, month, day)
  const size = getCompletePosterSize(lunarPoster.customizationInfo.size)
  const isCustomized = !!customizedAt

  return (
    <ListItem
      destinationPath={destinationPath}
      customizableProduct={lunarPoster}
    >
      <ListItem.Thumbnail>{thumbnail}</ListItem.Thumbnail>
      <ListItem.Body>
        {hasColorInfo && (
          <span>
            <span className='font-semibold'>Color: </span>{' '}
            {startCase(background)}
          </span>
        )}
        <span>
          <span className='font-semibold'>Size: </span> {size}
        </span>
        <span>
          <span className='font-semibold'>Frame: </span> {startCase(frame)}
        </span>
        {date.isValid && (
          <span>
            <span className='font-semibold'>Date: </span>{' '}
            {date.toFormat('MMMM dd, yyyy')}
          </span>
        )}
        {hemisphere && (
          <span>
            <span className='font-semibold'>Hemisphere: </span>{' '}
            {startCase(hemisphere)}
          </span>
        )}
        {moonPhase && (
          <span>
            <span className='font-semibold'>Moon Phase: </span>{' '}
            {startCase(moonPhase.friendlyName)}
          </span>
        )}
        {place && (
          <span>
            <span className='font-semibold'>Location: </span> {place}
          </span>
        )}
        {message && (
          <span>
            <span className='font-semibold'>Message: </span> {message}
          </span>
        )}
      </ListItem.Body>
      {!isCustomized && (
        <ListItem.DisclosureIndicator>
          <ChevronRightIcon className={`w-6 h-full sm:w-7 md:w-8`} />
        </ListItem.DisclosureIndicator>
      )}
    </ListItem>
  )
}

const NonCustomizedLunarPosterListItem: FC<{
  lunarPoster: Product.LunarPoster
}> = ({ lunarPoster }) => {
  return (
    <LunarPosterListItem
      lunarPoster={lunarPoster}
      destinationPath='lunar-poster'
      thumbnail={
        <ListItemIcon>
          <LunarPosterIcon />
        </ListItemIcon>
      }
    />
  )
}

const CustomizedLunarPosterListItem: FC<{
  lunarPoster: Product.LunarPoster
}> = ({ lunarPoster }) => {
  const previewImageRef = React.useRef<HTMLImageElement>()
  const [previewImageUrl, setPreviewImageUrl] = React.useState<string>()

  React.useEffect(() => {
    if (!previewImageRef.current) {
      return
    }

    const width = previewImageRef.current.clientWidth * window.devicePixelRatio

    const previewImageUrl = getLunarPosterPreviewImageUrl(
      lunarPoster.customizationInfo,
      { width }
    )

    setPreviewImageUrl(previewImageUrl)
  }, [previewImageRef.current])

  return (
    <LunarPosterListItem
      lunarPoster={lunarPoster}
      thumbnail={
        <PreviewImage ref={previewImageRef} imageSource={previewImageUrl} />
      }
    />
  )
}

export const ListItemByStatus: ListItemByStatusProps = {
  NonCustomized: ({ customizableProduct }) =>
    isMoonJewelryItem(customizableProduct) ? (
      <NonCustomizedLunarTalismanListItem lunarTalisman={customizableProduct} />
    ) : isLunarPoster(customizableProduct) ? (
      <NonCustomizedLunarPosterListItem lunarPoster={customizableProduct} />
    ) : null,

  Customized: ({ customizableProduct }) =>
    isMoonJewelryItem(customizableProduct) ? (
      <CustomizedLunarTalismanListItem lunarTalisman={customizableProduct} />
    ) : isLunarPoster(customizableProduct) ? (
      <CustomizedLunarPosterListItem lunarPoster={customizableProduct} />
    ) : null,
}

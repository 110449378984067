import type { FC } from 'react'
import type { RouteComponent } from '@ephemeris/types/src/reach-router'

import React from 'react'
import { Router, navigate } from '@reach/router'
import { Wizard } from 'react-use-wizard'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import compact from 'lodash/compact'

import { getChild } from '@ephemeris/utils/src/react'

import LunarTalismanWizard from './LunarTalismanWizard'
import LunarPosterWizard from './LunarPosterWizard'
import StepController from './StepController'

export interface CustomizationStep {
  name: string
  component: React.ReactNode
}

export type CustomizationSteps = (CustomizationStep | null)[]

type CustomizationWizardPageProps = RouteComponent<{
  customizableProduct: ProductCustomization.Item
}>

interface WizardContextProps {
  customizableProduct: ProductCustomization.Item
}

interface CustomizationWizardProps {
  steps: CustomizationStep[]
  onChangeStep?: (step: CustomizationStep, stepIndex: number) => void
  onConfirm?: () => void
}

interface CustomizationWizardComposites {
  Preview: FC
}

const CustomizationWizardContext =
  React.createContext<WizardContextProps>(undefined)

const CustomizationWizardProvider: FC<WizardContextProps> = props => (
  <CustomizationWizardContext.Provider value={props}>
    {props.children}
  </CustomizationWizardContext.Provider>
)

export function useCustomizationWizardContext() {
  const context = React.useContext(CustomizationWizardContext)
  if (!context) {
    throw new Error(`'useWizardContext' must be used within it's Provider`)
  }
  return context
}

export const CustomizationWizard: CustomizationWizardComposites &
  FC<CustomizationWizardProps> = ({
  children,
  onChangeStep,
  onConfirm,
  ...props
}) => {
  const steps = compact(props.steps)
  const preview = getChild(children, CustomizationWizard.Preview)

  return (
    <div className='relative flex justify-center w-full min-h-full lg:flex-col lg:items-center lg:justify-start lg:pt-11 bg-lightGold text-darkBlue'>
      <div className='flex flex-col items-center lg:flex-row lg:items-start'>
        <div className='lg:w-md'>{preview}</div>

        <div className='w-full max-w-md px-4 pb-6 lg:w-md lg:max-w-lg'>
          <div className='mt-8 lg:mt-0' />
          <Wizard
            footer={
              <StepController
                steps={steps}
                onChangeStep={onChangeStep}
                onConfirm={onConfirm}
              />
            }
          >
            {map(steps, ({ component }, index) => (
              <div key={`customiozation-step-${index}`}>{component}</div>
            ))}
          </Wizard>
        </div>
      </div>
    </div>
  )
}

CustomizationWizard.Preview = ({ children }) => <>{children}</>
CustomizationWizard.Preview.displayName = 'Preview'

export default function ({ location, ...props }: CustomizationWizardPageProps) {
  const { customizableProduct } = location?.state ?? {}
  const { orderId } = props as {
    orderId: string
  }

  if (isNil(customizableProduct)) {
    navigate(`/${orderId}`, { replace: true })
    return <></>
  }

  return (
    <CustomizationWizardProvider customizableProduct={customizableProduct}>
      <Router>
        <LunarTalismanWizard path='lunar-talisman' />
        <LunarPosterWizard path='lunar-poster' />
      </Router>
    </CustomizationWizardProvider>
  )
}

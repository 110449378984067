import type { FC } from 'react'
import type { RouteComponent } from '@ephemeris/types/src/reach-router'
import type { CustomizationSteps } from './CustomizationWizard'

import React from 'react'
import axios from 'axios'
import { DateTime } from 'luxon'
import { Buffer } from 'buffer'
import memoizee from 'memoizee'
import { AnimatePresence, motion } from 'framer-motion'
import { Listbox, Transition } from '@headlessui/react'
import emojiRegex from 'emoji-regex/RGI_Emoji'
import {
  CheckIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid'
import padStart from 'lodash/padStart'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'

import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import { getDocumentWidth } from '@ephemeris/utils/src/vanilla-javascript'
import { createBase64DataUrl } from '@ephemeris/utils/src/buffer'
import { safeParseJson } from '@ephemeris/utils/src/json'
import { getCompletePosterSize } from '@ephemeris/utils/src/product-customization'
import { MAX_NUMBER_OF_CHARACTERS_IN_POSTER_MESSAGE } from '@ephemeris/constants/src/fulfillment/product-customization'
import { getLunarPosterPreviewImageUrl } from '@ephemeris/utils/src/product-customization/lunar-poster'
import { useModal } from '@ephemeris/react-components/src/Modal'
import useWindowResize from '@ephemeris/react-components/src/hooks/useWindowResize'
import Loader from '@ephemeris/react-components/src/Loader'
import LunarPosterIcon from '@ephemeris/assets/src/images/icons/lunar-poster.inline.svg'

import PreviewImage from '../PreviewImage'
import {
  CustomizationWizard,
  useCustomizationWizardContext,
} from './CustomizationWizard'
import { useProductCustomizationContext } from '../ProductCustomizationProvider'

const DEFAULT_MESSAGE = 'Your Message Here'

const fetchPreview = memoizee(async (imageUrl: string) => {
  const { data, headers } = await axios.get(imageUrl, {
    responseType: 'arraybuffer',
  })
  const imageBuffer = Buffer.from(data, 'binary')
  const imageDataUrl = createBase64DataUrl(imageBuffer, 'image/png')
  const moonPhaseJson = headers['x-ephemeris-moon-phase']
  const moonPhase = safeParseJson<MoonPhase>(moonPhaseJson)

  return { imageDataUrl, moonPhase }
})

function hydrateCustomizationInfo(
  lunarPoster: Product.LunarPoster
): Product.LunarPoster {
  const { customizationInfo } = lunarPoster
  const { hemisphere = 'north', place = '' } = customizationInfo
  const { foreground = 'white', background = 'space_blue' } =
    customizationInfo.theme ?? {}
  const theme = { foreground, background }

  const { year, month, day } = (() => {
    const { year, month, day } = customizationInfo

    if (!isNil(year) && !isNil(month) && !isNil(day)) {
      return { year, month, day }
    }

    const now = DateTime.local()
    return { year: now.year, month: now.month, day: now.day }
  })()

  return {
    ...lunarPoster,
    customizationInfo: {
      ...customizationInfo,
      year,
      month,
      day,
      hemisphere,
      theme,
      place,
    },
  }
}

const CharacterCounter: FC<
  {
    maxNumberOfCharacters: number
    remainigCharactersCount: number
  } & React.HTMLAttributes<HTMLSpanElement>
> = ({ maxNumberOfCharacters, remainigCharactersCount, ...props }) => {
  const status: 'high' | 'medium' | 'low' = (() => {
    switch (true) {
      case remainigCharactersCount <= maxNumberOfCharacters / 4:
        return 'low'
      case remainigCharactersCount <= maxNumberOfCharacters / 2:
        return 'medium'
      default:
        return 'high'
    }
  })()

  return (
    <div
      className={`${props.className} flex items-center justify-center w-7 h-7 bg-opacity-75 rounded-full bg-darkBlue transition-opacity`}
    >
      <span
        className={`
          text-sm
          ${
            status === 'high'
              ? 'text-emerald-300'
              : status === 'medium'
              ? 'text-amber-300'
              : status === 'low'
              ? 'text-rose-400'
              : ''
          }
        `}
      >
        {remainigCharactersCount}
      </span>
    </div>
  )
}

const MomentStep: FC<{
  className?: string
  customizationInfo?: Product.LunarPoster['customizationInfo']
  onChangeInfo: (
    date: Pick<DateComponents, 'year' | 'month' | 'day'>,
    hemisphere: Hemisphere
  ) => void
}> = ({
  onChangeInfo: handleChangeInfo,
  customizationInfo,
  className = '',
}) => {
  const isoDate = (() => {
    const { year, month, day } = customizationInfo ?? {}
    if (!year || !month || !day) {
      return DateTime.local().toISODate()
    }

    const padZero = (value: number) => padStart(`${value}`, 2, '0')

    return `${year}-${padZero(month)}-${padZero(day)}`
  })()
  const [date, setDate] = React.useState<string>(isoDate)
  const [hemisphere, setHemisphere] = React.useState<Hemisphere>(
    customizationInfo?.hemisphere ?? 'north'
  )

  const onChangeDate = React.useMemo(
    () =>
      debounce(
        ({
          target: { value: isoDate },
        }: React.ChangeEvent<HTMLInputElement>) => {
          setDate(isoDate)
        },
        300
      ),
    []
  )

  React.useEffect(() => {
    const [year, month, day] = map(date.split('-'), Number)
    handleChangeInfo({ year, month, day }, hemisphere)
  }, [date, hemisphere])

  return (
    <div className={className}>
      <style>
        {
          '\
        input[type="date"]::-webkit-inner-spin-button,\
        input[type="date"]::-webkit-calendar-picker-indicator {\
            display: none;\
            -webkit-appearance: none;\
        }\
        input[type="date"]{\
          display:block;\
          -webkit-appearance: textfield;\
          -moz-appearance: textfield;\
        }\
      '
        }
      </style>
      <p className='block text-xl font-light lg:text-2xl'>
        What special moment do you want to immortalize?
      </p>

      <div className='flex gap-6 mt-4'>
        <div className='flex flex-col flex-grow'>
          <label className='font-bold'>Date:</label>
          <input
            className='flex items-center justify-between w-full px-4 py-3 text-left bg-white bg-opacity-75 border-2 rounded-xl border-darkBlue focus:outline-none'
            defaultValue={isoDate}
            type='date'
            onChange={onChangeDate}
          />
        </div>
        <div className='flex flex-col flex-grow'>
          <label className='font-bold'>Hemisphere:</label>
          <Listbox value={hemisphere} onChange={setHemisphere}>
            {({ open }) => (
              <div className='relative w-full'>
                <Listbox.Button className='flex items-center justify-between w-full px-4 py-3 text-left bg-white bg-opacity-75 border-2 rounded-xl border-darkBlue focus:outline-none'>
                  {startCase(hemisphere)}
                  {open ? (
                    <ChevronUpIcon className='w-6' />
                  ) : (
                    <ChevronDownIcon className='w-6' />
                  )}
                </Listbox.Button>
                <Transition
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Listbox.Options className='absolute flex flex-col w-full gap-2 p-2 mt-1 shadow-lg bg-gray-50 rounded-xl focus:outline-none'>
                    {map(['north', 'south'], hemisphere => {
                      return (
                        <Listbox.Option key={hemisphere} value={hemisphere}>
                          {({ active, selected }) => (
                            <div
                              className={`
                              flex items-center px-4 overflow-hidden rounded
                              ${active ? 'bg-darkBlue text-gray-50' : ''}
                            `}
                            >
                              {selected ? (
                                <CheckIcon className='w-4 mr-1' />
                              ) : (
                                <div className='mr-5' />
                              )}
                              <span className='py-2'>
                                {startCase(hemisphere)}
                              </span>
                            </div>
                          )}
                        </Listbox.Option>
                      )
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        </div>
      </div>
    </div>
  )
}

const MessageStep: FC<{
  customizationInfo?: Product.LunarPoster['customizationInfo']
  onChangeInfo: (message: string) => void
}> = ({ customizationInfo, onChangeInfo }) => {
  const [message, setMessage] = React.useState(customizationInfo?.message)
  const [messageInputStatus, setMessageInputStatus] = React.useState<
    'focused' | 'blurred'
  >('blurred')

  const onChangeMessage = React.useMemo(() => debounce(onChangeInfo, 500), [])
  const onChangeText = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    let { value: text } = target

    if (text.length > MAX_NUMBER_OF_CHARACTERS_IN_POSTER_MESSAGE) {
      text = text.substr(0, MAX_NUMBER_OF_CHARACTERS_IN_POSTER_MESSAGE)
    }

    const message = text.replace(emojiRegex(), '')

    setMessage(message)
    onChangeMessage(message)
  }
  const getRemainingCharactersCount = (message: string) =>
    MAX_NUMBER_OF_CHARACTERS_IN_POSTER_MESSAGE - message.length

  return (
    <div className='w-full'>
      <p className='block text-xl font-light lg:text-2xl'>Write a message</p>

      <div className='mt-4' />

      <div className='flex items-center justify-between'>
        <label className='font-bold '>
          Message (Max. {MAX_NUMBER_OF_CHARACTERS_IN_POSTER_MESSAGE} Chars.):
        </label>
        <CharacterCounter
          className={messageInputStatus === 'focused' ? '' : 'opacity-0'}
          maxNumberOfCharacters={MAX_NUMBER_OF_CHARACTERS_IN_POSTER_MESSAGE}
          remainigCharactersCount={getRemainingCharactersCount(message)}
        />
      </div>
      <input
        className='flex items-center justify-between w-full px-4 py-3 mt-1 text-left bg-white bg-opacity-75 border-2 rounded-xl border-darkBlue focus:outline-none'
        type='text'
        onChange={onChangeText}
        value={message}
        onFocus={() => {
          setMessageInputStatus('focused')
        }}
        onBlur={() => {
          setMessageInputStatus('blurred')
        }}
        autoFocus
      />
    </div>
  )
}

const ThemeThumbnail: FC<
  {
    theme: Product.WallArt.Poster.Theme
    isSelected: boolean
  } & JSX.IntrinsicElements['button']
> = ({ theme, isSelected, ...props }) => {
  const [isHovering, setIsHovering] = React.useState(false)
  const badgeRef = React.useRef<HTMLDivElement>()
  const { background } = theme
  const bgColor =
    background === 'space_blue'
      ? 'bg-darkBlue'
      : background === 'black'
      ? 'bg-black'
      : ''
  const badgeColor =
    background === 'space_blue'
      ? 'text-darkBlue'
      : background === 'black'
      ? 'text-black'
      : ''
  const backgroundColorName = startCase(background)

  return (
    <button
      className={`flex flex-col items-center cursor-pointer focus:outline-none`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...props}
    >
      <div className={`relative w-20 p-2 rounded-lg ${bgColor}`}>
        <div className={`w-full ${bgColor} text-[snow] rounded-lg`}>
          <div
            className={`
              transition-transform
              ${isHovering ? 'scale-110' : ''}
            `}
          >
            <LunarPosterIcon />
          </div>
        </div>
        {isSelected && (
          <AnimatePresence>
            <motion.div
              initial={{ scale: '0%' }}
              animate={{ scale: '100%' }}
              exit={{ scale: '0%' }}
              transition={{ type: 'spring' }}
              ref={badgeRef}
              className='absolute z-10 w-1/3 bg-white rounded-full -top-2 -right-2'
              style={{ height: badgeRef.current?.clientWidth ?? 0 }}
            >
              <CheckCircleIcon className={`w-full ${badgeColor}`} />
            </motion.div>
          </AnimatePresence>
        )}
      </div>
      <span className='text-sm'>{backgroundColorName}</span>
    </button>
  )
}

const ThemeStep: FC<{
  customizationInfo?: Product.LunarPoster['customizationInfo']
  onChangeInfo: (theme: Product.WallArt.Poster.Theme) => void
}> = ({ customizationInfo, onChangeInfo }) => {
  const spaceBlueTheme: Product.WallArt.Poster.Theme = {
    background: 'space_blue',
    foreground: 'white',
  }
  const blackTheme: Product.WallArt.Poster.Theme = {
    background: 'black',
    foreground: 'white',
  }

  const [selectedTheme, setSelectedTheme] = React.useState(
    customizationInfo?.theme ?? spaceBlueTheme
  )

  const handleSelectTheme = (theme: Product.WallArt.Poster.Theme) =>
    setSelectedTheme(theme)

  React.useEffect(() => {
    onChangeInfo(selectedTheme)
  }, [selectedTheme])

  return (
    <>
      <p className='block text-xl font-light lg:text-2xl'>Select a color</p>

      <div className='flex items-center gap-4 mt-4'>
        <ThemeThumbnail
          theme={spaceBlueTheme}
          isSelected={isEqual(selectedTheme, spaceBlueTheme)}
          onClick={() => {
            handleSelectTheme(spaceBlueTheme)
          }}
        />
        <ThemeThumbnail
          theme={blackTheme}
          isSelected={isEqual(selectedTheme, blackTheme)}
          onClick={() => {
            handleSelectTheme(blackTheme)
          }}
        />
      </div>
    </>
  )
}

const ReviewStep: FC<{
  customizationInfo: Product.LunarPoster['customizationInfo']
  lunarPoster: Product.LunarPoster
}> = ({ customizationInfo, lunarPoster }) => {
  const {
    year,
    month,
    day,
    theme,
    hemisphere,
    moonPhase,
    message,
    size,
    frame,
  } = customizationInfo
  const { shippingAddress } = lunarPoster
  const date = DateTime.utc(year, month, day)
  const formattedDate = date.toFormat('MMMM dd, yyyy')
  const formattedFrame = startCase(frame)
  const formattedSize = getCompletePosterSize(size)
  const moonPhaseName = startCase(moonPhase?.friendlyName)

  const InfoTitle: FC = ({ children }) => (
    <span className='text-sm font-bold'>{children}</span>
  )
  const InfoGroup: FC = ({ children }) => (
    <div className='flex justify-between w-full'>{children}</div>
  )
  const InfoItem: FC = ({ children }) => (
    <div className='flex flex-col w-full'>{children}</div>
  )

  return (
    <div>
      <span className='text-xl font-light lg:text-2xl'>
        Please, review the information below:
      </span>

      <div className='mt-4' />

      <InfoGroup>
        <InfoItem>
          <InfoTitle>Date:</InfoTitle>
          <span>{formattedDate}</span>
        </InfoItem>
        <InfoItem>
          <InfoTitle>Hemisphere:</InfoTitle>
          <span>{startCase(hemisphere)}</span>
        </InfoItem>
      </InfoGroup>

      <div className='mt-3' />

      <InfoGroup>
        <InfoItem>
          <InfoTitle>Color:</InfoTitle>
          <span>{startCase(theme.background)}</span>
        </InfoItem>
        <InfoItem>
          <InfoTitle>Moon Phase:</InfoTitle>
          <span>{moonPhaseName}</span>
        </InfoItem>
      </InfoGroup>

      <div className='mt-3' />

      <InfoGroup>
        <InfoItem>
          <InfoTitle>Message:</InfoTitle>
          <span>{message}</span>
        </InfoItem>
      </InfoGroup>

      <div className='mt-3' />

      <InfoGroup>
        <InfoItem>
          <InfoTitle>Size:</InfoTitle>
          <span>{formattedSize}</span>
        </InfoItem>
        <InfoItem>
          <InfoTitle>Frame:</InfoTitle>
          <span>{formattedFrame}</span>
        </InfoItem>
      </InfoGroup>
    </div>
  )
}

const LunarPosterWizard: FC<RouteComponent> = ({ navigate }) => {
  const { customizableProduct } = useCustomizationWizardContext()
  const {
    updateCustomizableProduct,
    setStatus: setProductCustomizationStatus,
  } = useProductCustomizationContext()
  const { showModal, dismissModal } = useModal()
  const queryFulfillmentApi = useFulfillmentApi()
  const lunarPoster = customizableProduct as Product.LunarPoster

  const [customizationInfo, setCustomizationInfo] = React.useState<
    Product.LunarPoster['customizationInfo'] | undefined
  >()
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] =
    React.useState(false)
  const [previewImageDataUrl, setPreviewImageDataUrl] = React.useState<string>()
  const [previewImageHeight, setPreviewImageHeight] = React.useState<number>(0)
  const previewImageContainerRef = React.useRef<HTMLDivElement>()
  const moonPhase = customizationInfo?.moonPhase

  const handleChangeMoment = (
    date: Pick<DateComponents, 'year' | 'month' | 'day'>,
    hemisphere: Hemisphere
  ) => {
    const { year, month, day } = date
    setCustomizationInfo({ ...customizationInfo, year, month, day, hemisphere })
  }

  const handleChangeMessage = (message: string) =>
    setCustomizationInfo({ ...customizationInfo, message: message.trim() })

  const handleChangeTheme = (theme: Product.WallArt.Poster.Theme) =>
    setCustomizationInfo({ ...customizationInfo, theme })

  const handleConfirm = async () => {
    setProductCustomizationStatus('customizing')

    try {
      const poster = { ...lunarPoster, customizationInfo }
      const response = await queryFulfillmentApi(
        `/poster/${lunarPoster.id}/customize`,
        { poster },
        'POST'
      )
      const { poster: customizedPoster } = (await response.json()) as {
        poster: Product.LunarPoster
      }

      updateCustomizableProduct(customizedPoster)

      navigate('../', { replace: true })
    } catch (error) {
      console.log(`--- error:`, error)
      showModal({
        title: 'An error occurred',
        message: `This not your fault. Please, try again in a few minutes.`,
        body: (
          <div className='text-sm md:text-base'>
            <span className='block'>
              If the problem persists, please, contact us at{' '}
              <a href='mailto:help@ephemeris.co' className='underline'>
                help@ephemeris.co
              </a>
            </span>
            <button
              className='p-2 mt-4 border-2 rounded-lg text-darkBlue border-darkBlue'
              onClick={dismissModal}
            >
              Close
            </button>
          </div>
        ),
      })
    } finally {
      setProductCustomizationStatus('idle')
    }
  }

  const steps: CustomizationSteps = [
    {
      name: 'Moment',
      component: (
        <MomentStep
          customizationInfo={customizationInfo}
          onChangeInfo={handleChangeMoment}
        />
      ),
    },
    {
      name: 'Message',
      component: (
        <MessageStep
          customizationInfo={{ ...customizationInfo, message: '' }}
          onChangeInfo={handleChangeMessage}
        />
      ),
    },
    {
      name: 'Color',
      component: (
        <ThemeStep
          customizationInfo={customizationInfo}
          onChangeInfo={handleChangeTheme}
        />
      ),
    },
    {
      name: 'Review',
      component: (
        <ReviewStep
          customizationInfo={customizationInfo}
          lunarPoster={lunarPoster}
        />
      ),
    },
  ]

  React.useEffect(() => {
    const { customizationInfo } = hydrateCustomizationInfo(lunarPoster)
    setCustomizationInfo(customizationInfo)
  }, [])

  React.useEffect(() => {
    if (isNil(customizationInfo)) {
      return
    }

    setIsLoadingPreviewImage(true)

    const width = getDocumentWidth()

    const imageUrl = getLunarPosterPreviewImageUrl(customizationInfo, {
      defaultMessage: DEFAULT_MESSAGE,
      width,
    })

    const refreshPreviewImage = async () => {
      try {
        const { imageDataUrl, moonPhase } = await fetchPreview(imageUrl)

        setCustomizationInfo({ ...customizationInfo, moonPhase })
        setPreviewImageDataUrl(imageDataUrl)
      } finally {
        setIsLoadingPreviewImage(false)
      }
    }

    refreshPreviewImage()
  }, [
    customizationInfo?.year,
    customizationInfo?.month,
    customizationInfo?.day,
    customizationInfo?.hemisphere,
    customizationInfo?.theme,
    customizationInfo?.message,
    customizationInfo?.place,
  ])

  useWindowResize(() => {
    const previewImageContainer = previewImageContainerRef.current
    const size = customizationInfo?.size ?? '12x16'

    if (isNil(previewImageContainer)) {
      return
    }

    const [width, height] = size.split('x').map(Number)
    const aspectRatio = width / height
    const previewImageHeight = previewImageContainer.clientWidth / aspectRatio

    setPreviewImageHeight(previewImageHeight)
  }, [previewImageContainerRef.current])

  return (
    <CustomizationWizard steps={steps} onConfirm={handleConfirm}>
      <CustomizationWizard.Preview>
        <div className='relative'>
          <div
            className='relative w-full min-w-screen min-h-w-screen lg:min-h-md lg:min-w-md'
            ref={previewImageContainerRef}
            style={{
              height: previewImageHeight,
            }}
          >
            <div className='absolute inset-0'>
              <PreviewImage previewImageUrl={previewImageDataUrl} />
            </div>
          </div>
          <div
            className={`
                absolute top-0 left-0 flex items-center justify-center w-full h-full bg-opacity-50 bg-darkBlue transition-opacity
                ${isLoadingPreviewImage ? 'opacity-100' : 'opacity-0'}
              `}
          >
            <Loader style='light' />
          </div>
        </div>

        <div className='mt-6' />

        <span className='block w-full mt-2 text-sm font-bold text-center'>
          Moon Phase:
        </span>
        <div className='relative'>
          <div
            className={`
              absolute flex flex-col items-center justify-center w-full h-full transition-opacity
              ${isLoadingPreviewImage ? 'opacity-100' : 'opacity-0'}
            `}
          >
            <div
              className={`w-1/3 h-4 rounded bg-darkBlue bg-opacity-30 animate-pulse transition-opacity`}
            />
          </div>
          <p
            className={`
              block text-xl lg:text-2xl font-light text-center transition-opacity
              ${
                isLoadingPreviewImage && !isNil(moonPhase)
                  ? 'opacity-0'
                  : 'opacity-100'
              }
            `}
          >
            {startCase(moonPhase?.friendlyName)}
          </p>
        </div>
      </CustomizationWizard.Preview>
    </CustomizationWizard>
  )
}

export default LunarPosterWizard

import type { FC } from 'react'

import React from 'react'

interface PreviewImageProps {
  previewImageUrl: string
}

export const PreviewImage: FC<PreviewImageProps> = ({ previewImageUrl }) => {
  return (
    <img
      className='w-full h-full border-b-2 border-gray-300 lg:border-2'
      src={previewImageUrl}
    />
  )
}

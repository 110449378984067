import type { FC } from 'react'
import type { Actions } from './actions'

import React from 'react'
import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import _ from 'lodash'

import {
  isJewelryItem,
  isPoster,
} from '@ephemeris/utils/src/product-customization'
import createProvider from '@ephemeris/react-components/src/createProvider'

import actions from './actions'
import reducer from './reducer'

export interface State {
  customizableProducts: ProductCustomization.Item[]
  status: ProductCustomizationStatus
}
export type ActionType =
  | 'SET_CUSTOMIZABLE_PRODUCTS'
  | 'UPDATE_CUSTOMIZABLE_PRODUCT'
  | 'SET_STATUS'
export type ProductCustomizationStatus = 'idle' | 'loading' | 'customizing'

const { provider: Provider, useContextHook: useProductCustomizationContext } =
  createProvider<State, ActionType, Actions>({
    initialState: { customizableProducts: undefined, status: 'idle' },
    reducer,
    actions,
  })

const ProductCustomizationProviderInitizalizer: FC<{ orderId: string }> = ({
  children,
  orderId,
}) => {
  const queryFulfillmentApi = (() => {
    try {
      return useFulfillmentApi()
    } catch {
      return () => null
    }
  })()

  const { setCustomizableProducts } = useProductCustomizationContext()

  React.useEffect(() => {
    const fetchPosters = async () => {
      const response = await queryFulfillmentApi(
        `/product-customization/${orderId}`
      )
      const { customizableItems } = (await response.json()) as {
        customizableItems: ProductCustomization.Item[]
      }
      const customizableProducts = _.filter(
        customizableItems,
        customizableItem =>
          isJewelryItem(customizableItem) || isPoster(customizableItem)
      )

      setCustomizableProducts(customizableProducts)
    }

    fetchPosters()
  }, [])

  return <>{children}</>
}

const ProductCustomizationProvider: FC<{ orderId: string }> = ({
  children,
  orderId,
}) => {
  return (
    <Provider>
      <ProductCustomizationProviderInitizalizer orderId={orderId}>
        {children}
      </ProductCustomizationProviderInitizalizer>
    </Provider>
  )
}

export { ProductCustomizationProvider, useProductCustomizationContext }

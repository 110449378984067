import React, {
  FC,
  useState,
  useContext,
  createContext,
  useEffect,
} from 'react'
import { isNil } from 'lodash'
import ReactDOM from 'react-dom'
import useKeypress from 'react-use-keypress'

export const Modal: FC = () => {
  if (typeof document === 'undefined') {
    return null
  }

  const { content, setContent, rootElementId } = useModalContext()
  const { title, message, body, onShow } = content ?? {}
  const rootElement = document.querySelector(`#${rootElementId}`)

  useKeypress('Escape', () => {
    setContent(undefined)
  })

  useEffect(() => {
    if (isNil(onShow)) {
      return
    }

    onShow()
  }, [])

  if (isNil(content)) {
    return null
  }

  if (isNil(rootElement)) {
    throw new Error(
      `A root element with the id ${rootElementId} could not be found`
    )
  }

  return ReactDOM.createPortal(
    <div className='fixed inset-0 flex items-center justify-center w-screen h-screen bg-gray-900 bg-opacity-30 z-max bg-radial-gradient-30'>
      <div className='relative flex flex-col items-start max-w-[90%] sm:max-w-md md:max-w-lg lg:max-w-xl px-6 py-8 bg-white rounded-lg shadow-lg text-darkBlue'>
        <h1 className='text-xl font-bold md:text-2xl'>{title}</h1>

        <div className='mt-2' />

        {message && <h3 className='text-sm md:text-base'>{message}</h3>}
        <div>{body}</div>
      </div>
    </div>,
    document.querySelector(`#${rootElementId}`)!
  )
}

interface ModalContent {
  title: string
  message?: string
  body?: React.ReactNode
  onShow?: () => void
  useRootModalComponent?: boolean
}

interface ModalContextProps {
  setContent: (content: ModalContent | undefined) => void
  content?: ModalContent
  rootElementId?: string
}

const ModalContext = createContext<ModalContextProps>(
  undefined as unknown as ModalContextProps
)

export const useModalContext = () => {
  return useContext(ModalContext)
}

export const ModalProvider: FC<{ rootElementId?: string }> = ({
  children,
  rootElementId,
}) => {
  const [content, setContent] = useState<ModalContent | undefined>()
  const { useRootModalComponent = true } = content ?? {}

  if (useRootModalComponent && isNil(rootElementId)) {
    throw new Error(
      'A rootElementId must be provided when useRootModalComponent is `true`.'
    )
  }

  return (
    <ModalContext.Provider value={{ setContent, content, rootElementId }}>
      {useRootModalComponent && <Modal />}
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const { setContent } = useModalContext()

  const dismissModal = () => {
    setContent(undefined)
  }
  const showModal = (content: ModalContent) => {
    setContent(content)
  }

  return { dismissModal, showModal }
}

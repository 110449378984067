export function getLunarPosterPreviewImageUrl(
  customizationInfo: Product.LunarPoster['customizationInfo'],
  options?: FullyPartial<{ defaultMessage: string; width: number }>
) {
  const { year, month, day, hemisphere, theme, place, message, size } =
    customizationInfo
  let url = new URL(
    `${process.env.GATSBY_RENDERING_API_URL}/lunar/poster/preview.jpg`
  )

  url.searchParams.append('year', `${year}`)
  url.searchParams.append('month', `${month}`)
  url.searchParams.append('day', `${day}`)
  url.searchParams.append('hemisphere', hemisphere)
  url.searchParams.append('place', place)
  url.searchParams.append('message', message ?? options?.defaultMessage ?? '')
  url.searchParams.append('size', size)
  url.searchParams.append('background', theme.background)
  url.searchParams.append('foreground', theme.foreground)
  url.searchParams.append('width', `${options?.width ?? 300}`)

  return url.toString()
}
